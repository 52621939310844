@import '../../styles/mixins';

.headerBar {
  width: 100%;
  max-width: var(--content-max-width);
  height: 100px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 1;

  &__mainNavSm {
    display: none;
  }

  @include tablet {
    position: fixed;
    height: 50px;
    background-color: white;
    align-items: center;

    .headerBar__logo {
      // width: 90px;
      width: auto;
      height: 32px;
      left: 64px;
      bottom: 8px;
      transform: none;
    }

    .headerBar__mainNavSm {
      display: block;
    }

    .headerBar__mainNavLg {
      display: none;
    }

    .headerBar__langs {
      display: none;
    }

    .headerBar__functions {
      // display: none;
    }
  }
}