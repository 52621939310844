$sm-width:768px;
$md-width:992px;
$lg-width:1200px;

@mixin mobile {
  @media (max-width:$sm-width) {
    @content;
  }
}

@mixin tablet {
  @media (max-width:$md-width) {
    @content;
  }
}

@mixin desktop {
  @media (max-width:$lg-width) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width:$lg-width + 1) {
    @content;
  }
}