@import '../../styles/mixins';

#pageCheckOut {
  .formWrapper {
    display: flex;
    flex-direction: row;
    gap: 60px;

    >* {
      width: 100%;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .formGroup {
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .btnContainer {
    margin: 30px 0;
    text-align: center;
  }
}