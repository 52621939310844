@import '../../styles/mixins';

#pageHome {
  .news-container {
    display: grid;
    grid-template-columns: 1fr 440px;
    gap: 6px;
    // height: 250px;

    @include desktop {
      grid-template-columns: 1fr 360px;
    }

    @include tablet {
      grid-template-columns: 1fr;
      // grid-template-rows: max-content;
      height: auto;

      >* {
        // height: 250px;
      }
    }

    @include mobile {
      grid-template-columns: 1fr;
      height: auto;
    }
  }

  .sectionHeader {
    margin-bottom: 10px;

    &__title {
      font-size: 3.2rem;
      font-weight: bold;
      color: var(--color-primary);
      margin-right: 10px;
    }

    &__link {
      cursor: pointer;

      a {
        color: #818284;
        text-decoration: none;
      }
    }
  }
}