#pageContact {
  .formWrapper {
    width: 100%;
    max-width: 500px;
  }

  .remarks {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .formGroup {
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .messageBox {
    background-color: rgba(255, 133, 133, 0.3);
    border-radius: 5px;
    padding: 20px 20px;
    margin-bottom: 30px;
    font-size: 1.5rem;
  }
}