@import '../../../styles/mixins';

.cartListItem {
  display: grid;
  grid-template-columns: 80px 1fr 150px 120px 100px min-content;
  grid-template-areas: 'img desc qty price totalPrice delete'
    'img msg msg msg msg msg';
  gap: 14px;
  margin-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--color-grey-light-1);

  @include tablet {
    grid-template-columns: 80px 1fr 100px 100px;
    grid-auto-rows: 1fr;
    grid-template-areas: 'img desc qty delete'
      'img desc price totalPrice';
  }

  @include mobile {
    grid-template-columns: 60px 1fr min-content;
    grid-auto-rows: min-content;
    grid-template-areas: 'img desc delete'
      'img price price'
      'img totalPrice totalPrice'
      'img qty qty';
    gap: 6px 10px;
  }

  >* {
    // border: 1px solid #eee;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 1.5rem;

    // vertical-align: middle;
    // padding: 8px 5px;
  }

  &__img {
    grid-area: img;
    margin-bottom: auto;

    img {
      object-fit: contain;
    }
  }

  &__desc {
    grid-area: desc;
    flex-direction: column;
    justify-content: center;

    >* {
      margin-right: auto;
    }

    .brand {
      font-weight: bold;
    }
  }

  &__qty {
    grid-area: qty;
    align-items: center;
    justify-content: flex-end;

    @include mobile {
      justify-content: flex-start;
    }

    .qty {
      width: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 3px 5px;
      font-size: 1.5rem;
    }
  }

  &__price {
    grid-area: price;
    align-items: center;
  }

  &__totalPrice {
    grid-area: totalPrice;
    align-items: center;
    justify-content: flex-end;
    color: var(--color-primary);
    font-weight: bold;

    @include mobile {
      justify-content: flex-start;
    }
  }

  &__delete {
    grid-area: delete;
    align-items: center;
    justify-content: flex-end;

    @include mobile {
      align-items: flex-start;
    }
  }

  &__msg {
    grid-area: msg;
    justify-content: flex-end;
    color: var(--color-warning);
  }
}