:root {
  // COLORS
  --color-primary: #DA3832;
  --color-primary-light: #DA3832;
  --color-primary-dark: #8d1e1a;
  --color-secondary: #B1D9D5;
  --color-secondary-light: #c5e2df;
  --color-secondary-dark: #183C48;
  --color-tertiary: #2998ff;
  --color-tertiary-light: #B1D9D5;
  --color-tertiary-dark: #5643fa;

  --color-grey: #ccc;
  --color-grey-dark: #333;

  --color-grey-dark-1: #999;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #444;
  --color-grey-light-1: #f7f7f7;
  --color-grey-light-2: #eee;
  --color-grey-light-3: #ddd;
  --color-default-text: #221f20;

  --color-success: #33a35e;
  --color-warning: #f33029;

  --shadow-1: 0 3px 8px rgba(0, 0, 0, 0.4);

  // FONTS
  --default-font-size: 1.6rem;

  --content-max-width: 1280px;

}