@import '../../styles/mixins';

#transactionDetails {
  .header {
    display: grid;
    // background-color: #ccc;
    grid-template-columns: 1fr 1fr;
    gap: 50px;

    @include mobile {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  .product-details {
    .total-amount {
      text-align: right;

      @include tablet {
        text-align: left;
      }
    }
  }
}