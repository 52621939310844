#pagePaymentSuccess {
  .paymentDetails {

    th,
    td {
      text-align: left;
      padding: 3px 20px 3px 0;
    }

    th {
      font-weight: bold;
    }

    .paymentStatus {
      font-weight: bold;

      &.failed {
        color: var(--color-warning);
      }

      &.successed {
        color: #13a300;
      }
    }
  }
}