#pageMyAccountTransactions {
  .table-transactions {
    width: 100%;

    tr {
      border-bottom: 1px solid var(--color-grey-light-2);
    }

    th {
      font-weight: bold;
    }

    th,
    td {
      text-align: left;
      padding: 5px 5px;
    }

    .currency {
      width: 60px;
    }

    .amount {
      text-align: right;
    }

    .actions {
      text-align: right;
      width: 150px;
      padding-left: 50px;
    }
  }
}