#pageBrands {
  .sectionHeader {
    margin-bottom: 10px;

    &__title {
      font-size: 3.2rem;
      font-weight: bold;
      color: var(--color-primary);
      margin-right: 10px;
    }
  }

  .loadMoreWrapper {
    width: 100%;
    text-align: center;
    margin: 30px 0;
  }

  .brandList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    a {
      color: var(--color-default-text);
      text-decoration: none;
      font-size: 2rem;

      &.selected {
        color: var(--color-primary);
      }
    }
  }
}