#pagePromotion {
  .sectionHeader {
    margin-bottom: 10px;

    &__title {
      font-size: 3.2rem;
      font-weight: bold;
      color: var(--color-primary);
      margin-right: 10px;
    }
  }

  .loadMoreWrapper {
    text-align: center;
    margin: 40px 0;
    display: flex;
    justify-content: center;
  }
}