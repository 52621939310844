@import '../../styles/mixins';

#pageCart {

  .btnContainer {
    text-align: right;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include mobile {
      justify-content: center;
    }
  }
}