#pageNewItems {
  .sectionHeader {
    margin-bottom: 10px;

    &__title {
      font-size: 3.2rem;
      font-weight: bold;
      color: var(--color-primary);
      margin-right: 10px;
    }
  }

  .loadMoreWrapper {
    width: 100%;
    text-align: center;
    margin: 30px 0;
  }
}