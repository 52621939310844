.slideContainer {
  position: relative;
  z-index: 0;

  .prev,
  .next {
    z-index: 9;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba($color: #000000, $alpha: 0.15);
    color: white;
    padding: 20px 0;
    text-align: center;
    transition: all 200ms;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.4);
      // opacity: 0.8;
    }
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}

.slideBanner {
  width: 100%;
  height: 280px;
  z-index: 0;

  div {
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}