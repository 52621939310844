@import './mixins';

.container {
  width: 100%;
  max-width: var(--content-max-width);
  margin: 0 auto;
  flex: 1;
  flex-grow: 1;

  >.row {
    width: 100%;
    padding: 20px 20px;

    @include mobile {
      padding: 20px 10px;
    }
  }
}

.container-full {
  width: 100%;
  margin: 0 auto;
  flex: 1;
  flex-grow: 1;

  >.row {
    width: 100%;
    padding: 15px 20px;

    @include mobile {
      padding: 20px 10px;
    }
  }
}

.no-underline {
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.pageTitle {
  font-size: 3.2rem;
  font-weight: 700;
  color: #58595B;
  margin-bottom: 30px;
}

.formGroup {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  gap: 10px;

  >* {
    width: 100%;
  }
}

.remarks {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

div.splitter {
  height: 1px;
  background-color: var(--color-grey);
  width: 100%;
  clear: both;
}

.responsive-table {
  width: 100%;

  @include tablet {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }

  table {
    width: 100%;
  }
}

.paper {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  padding: 10px;
  margin-top: 0;
  margin-bottom: 20px;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}