@import '../../styles/mixins';

#productDetails {
  width: 100%;

  .card {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    &__mainboard {
      overflow-y: scroll;
      width: 100%;
      padding: 0 10px;
      // background-color: #ddd;

      @include mobile {
        padding: 0;
        overflow-y: auto;
      }

      .img-wrapper {
        width: 100%;
        margin-bottom: 15px;
        display: none;

        @include mobile {
          display: block;
        }

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .actions {
        display: none;

        @include mobile {
          display: block;
        }
      }
    }

    &__sideboard {
      width: 400px;
      max-width: 400px;
      padding: 0 10px;

      @include mobile {
        display: none;
      }

      .img-wrapper {
        width: 100%;
        margin-bottom: 15px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &__brand {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 5px;
      }

      &__content {
        margin-bottom: 20px;
        font-size: 1.5rem;
        height: 120px;
        overflow: hidden;
      }

      &__price {
        color: var(--color-primary);
        margin-bottom: 10px;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        &__subject {
          font-size: 1.6rem;
        }

        &__unitprice {
          font-size: 2rem;
          font-weight: bold;
        }
      }

      &__qty {
        width: 50px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 3px 5px;
        font-size: 1.5rem;
      }

      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
      }
    }

    &__partition {
      margin-bottom: 30px;

      h4 {
        margin: 0 0 5px;
      }

      &.heading {
        font-size: 2.2rem;
        font-weight: bold;
      }
    }

    &__specifications table {
      width: 100%;

      tr:nth-child(odd) {
        background: #D1D3D4;
      }

      td {
        font-size: 1.5rem;
        padding: 8px;
      }
    }
  }
}