#popupCard {
  display: none;

  &.show {
    display: block;
  }

  .backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .foreground {
    background-color: white;
    box-shadow: var(--shadow-1);
    width: 95%;
    max-width: 1100px;
    height: 100%;
    max-height: 80vh;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__closeBtn {
      position: absolute;
      right: -8px;
      top: -8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: var(--color-secondary);
      border-radius: 50%;
      cursor: pointer;
      box-shadow: var(--shadow-1);
      z-index: 1001;
    }
  }

  .contentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
}