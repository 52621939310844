@import '../../styles/mixins';

.homeNewsBox {
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-color: #b1d9d5;
  display: grid;
  grid-template-columns: 1fr 320px;
  cursor: pointer;

  &__textWrapper {
    padding: 15px;
    display: flex;
    flex-direction: column;

    .title {
      color: white;
      font-size: 3rem;
      margin-bottom: 10px;
    }

    .subject {
      font-weight: bold;
      padding-bottom: 10px;
    }

    .content {
      flex: 1;
      min-height: 60px;
      max-height: 120px;
      overflow: hidden;
    }

    .readMore {
      padding-top: 5px;
    }
  }

  &__imgWrapper {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include mobile {
      height: 240px;
    }
  }

  @include mobile {
    grid-template-columns: 1fr;
  }
}