.imgUploader {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 40px;

  &__notes {
    margin-bottom: 2rem;
  }

  &__selectFile {
    display: flex;
    flex-direction: column;
  }

  &__preview {
    min-height: 40px;
    flex: 1;
  }

  &__viewer {
    padding: 20px 20px 20px 30px;
    border-left: 1px solid #ccc;
  }
}