@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;500;700&display=swap');

body {
  font-family: "Arial", "Noto Sans SC", "Lato", "sans-serif";
  font-size: var(--default-font-size);
  font-weight: 300;
  line-height: 1.25;
  color: var(--color-default-text);
  word-wrap: break-word;
}

h1 {
  font-size: 3.2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--color-grey-dark-1);
}

p {
  // font-size: var(--default-font-size);
  margin-bottom: 2rem;
}