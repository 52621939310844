@import '../../styles/mixins';

#pageNews {
  .news {
    width: 100%;
    clear: both;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid var(--color-grey-dark-1);
    display: table;

    &__imgbox {
      float: right;
      max-width: 330px;
      padding: 15px;

      @include mobile {
        float: none;
        max-width: 100%;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__subject {
      font-size: 2.1rem;
      font-weight: bold;
      padding-bottom: 10px;
    }

    &__date {
      color: var(--color-grey-dark-3);
      padding-bottom: 20px;
    }
  }
}