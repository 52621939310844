@import '../../styles/mixins';

.productList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

  @include desktop {
    grid-template-columns: repeat(4, 1fr);
  }

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}