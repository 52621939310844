#pageAdminSignIn {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F7F9;

  .formWrapper {
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--shadow-1);
    padding: 30px 20px;
    margin: 10px;
  }

  .logo {
    margin: 0 auto 30px;

    img {
      height: 50px;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  .remarks {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .messageBox {
    background-color: rgba(255, 133, 133, 0.3);
    border-radius: 5px;
    padding: 20px 20px;
    margin-bottom: 30px;
    font-size: 1.5rem;
  }
}