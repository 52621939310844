@import '../../styles/mixins';

#pageFooter {
  background-color: #ddd;
  width: 100%;
  max-width: var(--content-max-width);
  margin: 20px auto 0;

  .links {
    background-color: #F6D4C4;
    font-size: 1.4rem;
    padding: 15px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include desktop {
      flex-direction: column;
      text-align: center;
      gap: 20px 5px;
    }

    a {
      text-decoration: none;
      color: var(--color-default-text);

      &:hover {
        text-decoration: underline;
      }
    }

    .largeLinks {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .smallLinks {
      font-size: 1.3rem;
    }

    .linksWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .supportsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 30px;

      >div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      @include mobile {
        flex-direction: column;
        gap: 20px;
      }
    }

    .iconFb,
    .iconIns {
      height: 30px;
      margin-left: 10px;
    }

    .iconStripe {
      height: 24px;
      margin-left: 8px;
    }
  }

  .contactInfo {
    background-color: var(--color-primary);
    color: white;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }

    @include desktop {
      flex-direction: column;
      text-align: center;
      gap: 5px;
      padding: 15px 10px;

      >* {
        width: 100%;
      }
    }
  }

  .copyright {
    font-size: 1.2rem;
    background-color: white;
    color: #818284;
    text-align: center;
    padding: 20px;
  }
}